<template>
  <div class="header-wrapper">
    <header class="header">
      <div class="container">
        <div class="row">
          <div class="col-xx-lg-2 col-x-lg-2 col-lg-2 col-xs-6">
            <router-link to="/" class="header__logo">
              <img src="~@/assets/img/logo.png" alt/>
            </router-link>
          </div>

          <div
            class="col-xx-lg-6 col-x-lg-5 col-lg-5 col-xs-6"
            v-if="!isEnableResponsive"
          >
            <nav class="header__nav">
              <router-link to="/#about-teraphy">Про програму</router-link>
              <router-link to="/pharmacies">Аптеки</router-link>
              <p
                :class="{active: currentRouteName === '/pulmonology' || currentRouteName === '/information' || menuDrop }"
                class="header__dropVisible">Терапевтичні напрями
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.992029 4.3363e-08L6 5.72458L11.008 4.81174e-07L12 1.14145L6 8L-4.98942e-08 1.14145L0.992029 4.3363e-08Z"
                    fill="#3C1053"/>
                </svg>

                <span class="header__drop">
                  <router-link class="header__drop-link" to="/pulmonology">Пульмонологія</router-link>
                  <router-link class="header__drop-link" to="/information">Кардіологія</router-link>
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Нефрологія</a>-->
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Ендокринологія</a>-->
                </span>

              </p>
              <router-link to="/contacts">Контакти</router-link>
            </nav>
          </div>
          <div
            class="col-xx-lg-4 col-x-lg-5 col-lg-5"
            v-if="!isEnableResponsive"
          >
            <div class="header__options">
              <div class="header__info">
                <a href="tel:0 800 211 202" class="header__info-phone"
                >0 800 211 202</a
                >
                <div class="header__info-timework">щоденно з 9:00 до 21:00</div>
              </div>
              <div class="header__account">
                <router-link
                  to="/login"
                  class="header__account-btn header__account-btn_yellow"
                >Увійти
                </router-link
                >
                <router-link to="/registration" class="header__account-btn"
                >Реєстрація
                </router-link
                >
              </div>
            </div>
          </div>

          <!-- Begin mobile nav -->
          <div class="col-xs-6" v-if="isEnableResponsive">
            <div class="header__mobile-menu">
              <div class="mobile-menu" @click="toggleMobileMenu">
                <div
                  class="mobile-menu__icon"
                  :class="isMenuVisible ? 'active' : ''"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="header__mobile"
            v-if="isEnableResponsive"
            v-show="isMenuVisible"
          >
            <div class="container">
              <nav class="header__mobile-nav">
                <router-link to="/#about-teraphy">Про програму</router-link>
                <router-link to="/pharmacies">Аптеки</router-link>
                <p
                  :class="[{activeMob: currentRouteName === '/pulmonology' || currentRouteName === '/information' || menuDrop } , {drop: menuDrop}]"
                  class="header__dropVisible" @click="menuDrop = !menuDrop">Терапевтичні
                  напрями
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.992029 4.3363e-08L6 5.72458L11.008 4.81174e-07L12 1.14145L6 8L-4.98942e-08 1.14145L0.992029 4.3363e-08Z"
                      fill="#3C1053"/>
                  </svg>
                  <span class="header__drop header__drop--desk">
                  <router-link class="header__drop-link" to="/pulmonology">Пульмонологія</router-link>
                  <router-link class="header__drop-link" to="/information">Кардіологія</router-link>
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Нефрологія</a>-->
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Ендокринологія</a>-->
                </span>

                </p>
                <span v-if="menuDrop" class="header__drop header__drop--mob">
                  <router-link @click="menuDrop = !menuDrop" class="header__drop-link"
                               to="/pulmonology">Пульмонологія</router-link>
                  <router-link @click="menuDrop = !menuDrop" class="header__drop-link"
                               to="/information">Кардіологія</router-link>
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Нефрологія</a>-->
<!--                  <a class="header__drop-link header__drop-link&#45;&#45;disable">Ендокринологія</a>-->
                </span>

                <router-link to="/contacts">Контакти</router-link>
              </nav>
              <div class="header__account">
                <router-link
                  to="/login"
                  class="header__account-btn header__account-btn_yellow"
                >Увійти
                </router-link
                >
                <router-link to="/registration" class="header__account-btn"
                >Реєстрація
                </router-link
                >
              </div>
              <router-link to="/adverse-reaction" class="header__mobile-link"
              >Повідомити про побічну реакцію
              </router-link
              >
            </div>
          </div>
          <!-- // -->
        </div>
      </div>
    </header>
    <div class="header__contact-info" v-if="isVisibleContacts">
      <div class="container">
        <a href="tel:0 800 211 202">0 800 211 202</a>
        щоденно з 9:00 до 21:00
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Header",
  data() {
    return {
      isVisibleContacts: false,
      isEnableResponsive: false,
      isMenuVisible: false,
      menuDrop: false,

    };
  },

  watch: {
    '$route'(newVal, oldVal) {
      this.menuDrop = false
    }
  },
  computed: {
    ...mapGetters(["getResponsive"]),
    currentRouteName() {
      return this.$route.path.toLowerCase();
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    }
  },
  beforeMount() {
    if (window.innerWidth <= 1200) {
      this.isEnableResponsive = true;
      this.isVisibleContacts = true;


    }

  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1200) {
        this.isEnableResponsive = true;
        this.isVisibleContacts = true;
      } else {
        this.isEnableResponsive = false;
        this.isVisibleContacts = false;
      }
    });
    this.$router.beforeEach((to, from, next) => {
      this.isMenuVisible = false;
      next();
    });
  }
};
</script>
