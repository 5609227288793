<template>
  <div
    class="modal nearest-pharmasy"
    v-if="getNearestPharmacyModal && getConvertedToTempMapMarkers.length > 0"
  >
    <div class="modal__content">
      <div class="modal__close" @click="close()"></div>
      <h3 class="modal__title">
        Найближча аптека розташована
        <br/>за адресою
      </h3>
      <div class="modal__main">
        <div class="modal__pharmasy">
          <div
            class="modal__pharmasy-name"
            v-if="getConvertedToTempMapMarkers[0].title !== ''"
          >
            {{ getConvertedToTempMapMarkers[0].title }}
          </div>

          <div
            class="modal__pharmasy-city"
            v-if="getConvertedToTempMapMarkers[0].city !== ''"
          >
            {{ getConvertedToTempMapMarkers[0].city }}
          </div>
          <div
            class="modal__pharmasy-address"
            v-if="getConvertedToTempMapMarkers[0].address !== ''"
          >
            {{ getConvertedToTempMapMarkers[0].address }}
          </div>
          <div
            class="modal__pharmasy-phone"
            v-if="getConvertedToTempMapMarkers[0].phones[0] !== ''"
          >
            {{ getConvertedToTempMapMarkers[0].phones[0] }}
          </div>
<!--          <div-->
<!--            class="modal__pharmasy-timework"-->
<!--            v-if="getConvertedToTempMapMarkers[0].timeWork.weedkays !== ''"-->
<!--          >-->
<!--            {{ getConvertedToTempMapMarkers[0].timeWork.weekdays }}-->
<!--          </div>-->
          <div class="modal__pharmasy-link">
            <a
              @click="close()"
              target="_blank"
              :href="
                'https://www.google.com/maps/dir/' +
                  curLat +
                  ',' +
                  curLon

              "
            >Показати на мапі</a
            >
          </div>
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="close()"
          >Зрозуміло</a
          >
          <a
            href="#"
            class="modal__link modal__link_blue"
            @click.prevent="close()"
          >Скасувати</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "NearestPharmacy",
  data() {
    return {
      curLat: localStorage.curLat,
      curLon: localStorage.curLon,
    };
  },
  computed: {
    ...mapGetters(["getNearestPharmacyModal", "getConvertedToTempMapMarkers"]),
  },
  watch: {
    getNearestPharmacyModal: function () {
      this.curLat = localStorage.curLat; // userLoacation не обновляється, а curLat curLon обновляється.
      this.curLon = localStorage.curLon;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["setNearestPharmacyModal"]),
    close() {
      this.setNearestPharmacyModal(false);
    },
  },
};
</script>
