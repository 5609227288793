<template>
  <aside class="cookies" v-show="isVisible">
    <div class="cookies__content">
      <div class="cookies__close" @click="setCookies"></div>
      <div class="cookies__title">Політика Cookie</div>
      <div class="cookies__text">
        Цей сайт використовує файли cookies, щоб полегшити Ваше використання
        нашого веб-сайту. Продовжуючи використовувати цей веб-сайт, Ви даєте
        згоду на використання файлів cookies. Докладніше про те, як ми
        використовуємо файли cookies та як ними керувати, Ви можете дізнатися за
        <a
          class="cookies__text__link"
          href="https://cookienotice.astrazeneca.com"
          target="_blank"
          >посиланням</a
        >
      </div>
      <a href="#" @click.prevent="setCookies" class="cookies__btn"
        >Підтвердити</a
      >
    </div>
  </aside>
</template>

<script>
import Vue from "vue";
export default {
  name: "Cookies",
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    setCookies() {
      Vue.$cookies.set("cookies", "1");
      this.isVisible = false;
    }
  },
  mounted() {
    var cookie = this.$cookies.get("cookies");
    if (cookie === "1") {
      this.isVisible = false;
    }
  }
};
</script>
